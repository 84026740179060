import React from 'react';
import About from './About.js';
import Hero from './Hero.js';
import Newsfeed from './Newsfeed.js';
import Partnership from './Partnership.js';
import Team from './Team.js';

function Main() {
  return (
    <main>
      <Hero />
      <About />
      <Team />
      <Partnership />
      <Newsfeed />
    </main>
  );
}

export default Main;
