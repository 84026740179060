import React, { useEffect, useState } from 'react';
import { collection, addDoc, query, getDocs } from 'firebase/firestore';
import db from '../firebase';
import Select from 'react-select';
// import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

function Registration() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [faculty, setFaculty] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '' });
  const options = [
    {
      value: 'Agriculture',
      label: 'Agriculture',
    },
    {
      value: 'Arts',
      label: 'Arts',
    },
    {
      value: 'College of heath science',
      label: 'College of heath science',
    },
    {
      value: 'Communication and Information Science',
      label: 'Communication and Information Science',
    },
    {
      value: 'Faculty of Arts',
      label: 'Faculty of Arts',
    },
    {
      value: 'Engineering and Technology',
      label: 'Engineering and Technology',
    },
    {
      value: 'Environmental Science',
      label: 'Environmental Science',
    },
    {
      value: 'Law',
      label: 'Law',
    },
    {
      value: 'Life Science',
      label: 'Life Science',
    },
    {
      value: 'Management Science',
      label: 'Management Science',
    },
    {
      value: 'Pharmaceutical Science',
      label: 'Pharmaceutical Science',
    },
    {
      value: 'Physical Science',
      label: 'Physical Science',
    },
    {
      value: 'Social Science',
      label: 'Social Science',
    },
    {
      value: 'Veterinary Medicine',
      label: 'Veterinary Medicine',
    },
  ];

  // } else {
  //   // doc.data() will be undefined in this case
  //   console.log("No such document!");
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // check();
      await addDoc(collection(db, 'users'), {
        fname: fname,
        lname: lname,
        email: email,
        faculty: faculty,
      });

      setFname('');
      setLname('');
      setEmail('');
      setFaculty('');
      setLoading(false);
      // navigate('/');
      setAlert({ show: true, msg: 'Registration successful!' });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   function check() {
  //     if (email) {
  //       db.collection('users')
  //         .where('email', '==', email)
  //         .get()
  //         .then((querySnapshot) => {
  //           if (querySnapshot.size > 0) {
  //             console.log('The email exists in the Firestore database');
  //           } else {
  //             console.log('The email does not exist in the Firestore database');
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(`Error getting documents: ${error}`);
  //         });
  //     }
  //   }
  //   check();
  // }, [email]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert(true);
    }, 3000);
    return () => setTimeout(timeout);
  });
  return (
    <section class='w-screen bg-fwhite flex flex-col justify-center items-center py-16 text-black px-2'>
      <div class='bg-lightblue h-100 w-4/5 sm:w-3/5 md:w-2/5 p-4 rounded-sm'>
        <div class='mb-6 text-center'>
          <h1 class='font-bold text-blue text-2xl'>Welcome</h1>
          <p class='deepblue'>Glad to have you join us</p>
        </div>
        <form
          action=''
          onSubmit={handleSubmit}
          class='flex flex-col justify-between text-lg  gap-y-6'
        >
          <input
            type='text'
            id='fname'
            name='fname'
            placeholder='First name'
            class='h-12 p-2 rounded-sm outline-0'
            required
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
          <input
            type='text'
            id='lname'
            name='lname'
            placeholder='Last name'
            class='h-12 p-2 rounded-sm outline-0'
            required
            value={lname}
            onChange={(e) => setLname(e.target.value)}
          />
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Email'
            class='h-12 p-2 rounded-sm outline-0'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Select
            defaultValue={faculty}
            onChange={faculty}
            options={options}
            required
          />
          <button
            type='submit'
            class={`${
              loading ? 'bg-deepblue' : ' bg-blue'
            } h-10 text-white rounded-md`}
          >
            REGISTER
          </button>
        </form>
        <p>{alert.msg}</p>
      </div>
    </section>
  );
}

export default Registration;
