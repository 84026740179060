import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  AiOutlineMenu,
  AiOutlineCloseSquare,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
  AiOutlineLinkedin,
} from 'react-icons/ai';
import { SiTelegram } from 'react-icons/si';
import { Link } from 'react-router-dom';

function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <header class='w-full  bg-white border-b-2 border-deepblue'>
      <nav class='h-16 flex flex-row items-center justify-between px-4 sm:px-12 md:px-20'>
        <div class='h-16 w-16'>
          <Link to='/'>
            <img src='../images/bchainlogo.PNG' alt='' />
          </Link>
        </div>
        <div
          onClick={() => setIsSidebarOpen(true)}
          class='text-3xl cursor-pointer text-black hover:text-black sm:hidden  '
        >
          <AiOutlineMenu />
        </div>
        <div class='text-xl gap-x-4 text-black hidden sm:flex'>
          <Link to='/'>
            <h1 class=' cursor-pointer '>HOME</h1>
          </Link>
          <Link to='/course'>
            <h1 class=' cursor-pointer '>COURSES</h1>
          </Link>
          <Link to='/blog'>
            <h1 class='cursor-pointer'>BLOG</h1>
          </Link>
          <h1 class='cursor-pointer'>
            <a
              target='_blank'
              href='https://drive.google.com/drive/folders/1JconqeK51lCgxljVaF9Zvwt5kmGOU5d6'
            >
              DOCUMENTS
            </a>
          </h1>
        </div>
      </nav>
      <aside
        class={`w-2/3 bg-deepblue flex flex-col right-0 top-0 bottom-0 fixed p-6 sm:hidden duration-300 z-20 ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div
          onClick={() => setIsSidebarOpen(false)}
          class='text-4xl text-gray-300 flex justify-end cursor-pointer '
        >
          <AiOutlineCloseSquare class='text-white hover:bg-white hover:text-blue' />
        </div>
        <div class=' flex justify-center'>
          <img class='h-16 w-16' src='../images/bchainlogo.PNG' alt='' />
        </div>
        <div class='text-xl text-white  py-4 '>
          <Link to='/'>
            <h1
              onClick={() => setIsSidebarOpen(false)}
              class='border-b-2 cursor-pointer hover:text-blue py-2'
            >
              HOME
            </h1>
          </Link>
          <Link to='/course'>
            <h1
              onClick={() => setIsSidebarOpen(false)}
              class='border-b-2 cursor-pointer hover:text-blue py-2'
            >
              COURSES
            </h1>
          </Link>
          <Link to='/blog'>
            <h1
              onClick={() => setIsSidebarOpen(false)}
              class='border-b-2 cursor-pointer hover:text-blue py-2'
            >
              BLOG
            </h1>
          </Link>
          <h1
            onClick={() => setIsSidebarOpen(false)}
            class='border-b-2 cursor-pointer hover:text-blue py-2'
          >
            <a
              target='_blank'
              href='https://drive.google.com/drive/folders/1JconqeK51lCgxljVaF9Zvwt5kmGOU5d6'
            >
              DOCUMENTS
            </a>
          </h1>
        </div>
        <div class='fixed bottom-0 flex justify-around z-15 items-center w-full p-6 '>
          <div class='flex w-72 justify-around  text-white '>
            <a
              class='border border-white p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-white hover:text-blue '
              href='https://twitter.com/bchainunilorin'
              target='_blank'
            >
              <AiOutlineTwitter />
            </a>
            <a
              class='border border-white p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-white hover:text-blue'
              href='https://www.linkedin.com/company/blockchainunilorin'
              target='_blank'
            >
              <AiOutlineLinkedin />
            </a>
            <a
              class='border border-white p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-white hover:text-blue'
              href='https://t.me/bchainunilorin'
              target='_blank'
            >
              <SiTelegram />
            </a>
            <a
              class='border border-white p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-white hover:text-blue'
              target='_blank'
              href='https://chat.whatsapp.com/KgUcaG2W0JY0L5Go0vYU8g
'
            >
              <AiOutlineWhatsApp />
            </a>
          </div>
        </div>
      </aside>
    </header>
  );
}

export default Header;
