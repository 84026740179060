const teamData = [
  {
    id: 0,
    name: 'Abdulrouff Aderounmu',
    title: 'Co-Founder',
    bio: 'Abdurrouff is a blockchain enthusiast that has been in the space since 2019. He has worked for several blockchain projects as an ambassador and community manager.  He is a volunteered member at Stakeholders in Blockchain technology Association of Nigeria (SiBAN), volunteer mentor in NITDA blockchain scholarship program and has volunteered for many blockchain projects. He is a frontend developer looking into blockchain development. He is a passionate individual in blockchain and an advocate for the technology',
    twitter: 'https://twitter.com/remigbolahan',
    linkedIn: 'https://www.linkedin.com/in/abdulrouff-aderounmu-0ba05b23b/',
    img: '../images/rouff.jpeg',
    portfolio: 'https://abdulrouffaderounmu.vercel.app',
    details:
      'Business Developer || Blockchain enthusiast || Frontend Developer || Event manager || Community manager || Leadership || Public speaker',
  },
  {
    id: 1,
    name: 'Abdulkareem Mustapha',
    title: 'Co-Founder',
    bio: "Abdulkareem Mustapha, (also known as MUSTAZZY) is Blockchain/Crypto expert, He has been in the industry for almost five years experience.He worked with some exchanges as ambassador,  He also held both online and offline events, where He preaches Blockchain and its future. He's a trader and as well a trainer, He mentored several people. He's also a learner, He developed passion in graphics design and front end. He's an undergraduate student university of Ilorin",
    twitter: 'https://twitter.com/mustazzy157?t=UUU6M9Gee59oV-UJ0iic5g&s=09',
    linkedIn: 'https://www.linkedin.com/in/abdulkareem-mustapha-b661561a2',
    img: '../images/mustazzy.jpg',
    details:
      'Blockchain/Crypto expert // Blockchain Educator// Graphics designer(aspiring) // offline and online event planner // Fashion designer//',
  },
  {
    id: 2,
    name: 'Alimi Sherriffdeen Abiodun',
    title: 'Co-Founder',
    bio: 'A blockchain enthusiast with creativity in communism. A web3 project advisor, community manager, community mod and an ambassador. ',
    twitter: 'https://twitter.com/Intellygentle/',
    linkedIn: 'https://www.linkedin.com/in/adebayor-harbiodun-639659147/',
    img: '../images/sheriffdeen.png',
    details: '#Defi #Staking #Arbitrage #TA #Trading',
  },
  {
    id: 3,
    name: 'Abu Samuel Temitope',
    title: 'Head of Operations',
    bio: 'Civil Engr, Administrator /HOO @ Blockchain Unilorin , Fintech Expert, Crypto Enthusiast,  Realtor , Christian  , Multi -versatile , Superb Team Player , Great Inter personal  Skills ',
    twitter: 'https://twitter.com/abusamuel8',
    linkedIn: 'https://www.linkedin.com/in/abu-samuel-t-29b26b1b7',
    img: '../images/sam.jpg',
    details: '#Defi #Staking #Arbitrage #TA #Trading',
  },
  {
    id: 4,
    name: 'Hassan Ibrahim Akorede',
    title: 'Human Relations',
    bio: '',
    twitter: 'https://twitter.com/popo5156?s=09',
    linkedIn: 'https://www.linkedin.com/in/ibrahim-hassan-428679199',
    img: '../images/popo.jpg',
    details:
      'Hot line 📲 ||08145552565|| Civil engineering student |👨‍🔧|Affiliate &Digital marketer|💯| Scholar|🎉| Crypto trader|💵| Author & Researcher |📑|',
  },
  {
    id: 5,
    name: 'NORO',
    title: 'Community manager',
    bio: 'I work as a Community Manager & Moderator  and Social Media Manager.',
    twitter: 'https://twitter.com/theweirdghoul',
    linkedIn: 'https://www.linkedin.com/in/faithfulnessnikolaus22',
    img: '../images/noro.jpg',
  },
  {
    id: 6,
    name: 'Adeniran Eniola (Bênav)',
    title: 'Community manager',
    bio: "I'm a Student, who's interested in Blockchain Development (Web3 Space). I'm also a Frontend Developer (React Js). I always want to share or help people in the little way I can. I'm currently learning Decentralized Finance(Defi). I'm open to learning from others. ",
    twitter: 'https://twitter.com/Deola_04?t=tpR4FkJ5HUDQqb4MLkdwVA&s=09',
    linkedIn: 'https://www.linkedin.com/in/annaadeniran',
    img: '../images/eniola.jpg',
  },
  {
    id: 7,
    name: 'Olanrewaju Abdulbasit Gbolahan',
    title: 'Graphic design lead',
    bio: "I'm a skilled and passionate graphic designer with experience in both digital and print design. I have a strong attention to detail and am proficient in various design software. I excel in collaborating with clients, teams and stakeholders to produce visually appealing designs that resonate with audiences. I am adaptable and always looking for new challenges and I'm an AI enthusiast.",
    twitter: 'https://www.twitter.com/Excellus1',
    linkedIn: 'https://www.linkedin.com/in/olanrewaju-abdulbasit-65828b1aa',
    img: '../images/abdulbasit.jpg',
    details: 'gbolahanbasit43@gmail.com',
  },
  {
    id: 8,
    name: 'Abubakri Toyib',
    title: 'Graphic design',
    bio: 'A design specialist with 5+ years of experience. Loves creating brand identity and ad banners. ',
    twitter: 'https://twitter.com/Kuulbrand?t=c2WzaVGH5b11E-l-AiqgWQ&s=09',
    linkedIn:
      'Check out Toyib Abubakri’s profile on LinkedIn https://www.linkedin.com/in/toyib-abubakri-69a097255',
    img: '../images/toyib.png',
    portfolio: 'https://www.behance.net/gallery/157935601/CEIZA-Brand-Identity',
  },

  {
    id: 9,
    name: 'Onigbinde Abdulwasiu Ayomide ',
    title: 'Commuity builder',
    bio: 'Onigbinde Abdulwasiu Ayomide (also known as Whezelee) is an undergraduate student at the University of Ilorin in Nigeria. He is a python and Artificial intelligence learner, B.Eng in view, a blockchain advocate and community Growth Lead at Blockchain Unilorin. ',
    twitter: 'https://twitter.com/Honi_whiz?t=Ho8Bf7kWBthH9w-wIgpefg&s=09',
    linkedIn: 'https://www.linkedin.com/in/onigbinde-abdulwasiu-660976218',
    img: '../images/wasiu.jpg',
  },
  {
    id: 10,
    name: 'Yusuf Afeez Olamilekan',
    title: 'Community builder',
    bio: 'Student at Unilorin Community grower at Unilorin blockchain community',
    twitter: 'https://twitter.com/SnrCaptain2?t=UgL4r57LsP1h8qbYoO8sIg&s=09',
    linkedIn: 'https://www.linkedin.com/in/yusuf-afeez-a39613254',
    img: '../images/afeez.png',
  },
  {
    id: 11,
    name: 'HASSAN MOHAMMED',
    title: 'Web3 Advisor',
    bio: "I'm an Agricultural and Biosystems Engineer in training (Undergraduate), Architect, Estate manager, A certified painter, Arabic literate, and the Web3 Counselor of Unilorin Blockchain.",
    twitter: 'https://www.twitter.com/@opeloyeru999',
    linkedIn: 'https://www.linkedin.com/in/opeloyeru4real',
    img: '../images/mohammed.png',
    details:
      'A member of Nigerian Institution of Agricultural Engineers (NIAE)',
  },
  {
    id: 12,
    name: 'Yuusuf Abdullahi Temidayo',
    title: 'Full-stack Developer',
    bio: 'A MERN Stack Developer  that has passion for responsive website design, Also experienced in using some Technology like Typescript, Svelte and JavaScript also PHP and MySQL for the Backend side.',
    twitter: 'https://twitter.com/TemidayoYuusuf?s=09',
    linkedIn:
      'https://www.linkedin.com/in/yuusuf-abdullahi-temidayo-yusasive-958139213',
    img: '../images/yusuf.jpg',
    portfolio: 'https://yusufabdullahi.netlify.app/',
  },
  {
    id: 13,
    name: 'Maria Faustina Oche ',
    title: 'Content writer',
    bio: 'Maria is a web3 content creator and a social media manager; also a communicator who loves to speak on issues related to society. I believe so much can be achieved with the blockchain technology when it is well utilized. Also, I am of the believe that people do not have enough knowledge about the blockchain and that is one of the reasons I took interest in the blockchain; to educate people and make awareness about the blockchain technology and Web3',
    twitter: 'https://twitter.com/ochefaustina',
    linkedIn: 'https://www.linkedin.com/in/ochemariafaustina',
    img: '../images/maria.jpeg',
    details:
      'Blockchain&Web3 Content Creator, Social Media Manager & Communicator',
  },

  {
    id: 14,
    name: 'Ajagbe Afeez Akinwale',
    title: 'Blockchain Research lead',
    bio: 'Ajagbe is a blockchain enthusiast,Tech guru,Nft enthusiast that has been in the space since 2017. He has worked for several blockchain projects as a ambassador and community manager. He is a passionate individual in web3 and sees a lot of potential in it.',
    twitter: 'https://twitter.com/nobodyhave2',
    linkedIn: '',
    img: '../images/ajagbe.jpeg',
    details: 'WAGMI',
  },
  {
    id: 15,
    name: 'Adetoro Abdullahi',
    title: 'Blockchain Researcher',
    bio: '',
    twitter: 'https://twitter.com/To_roh7?t=aR24b1lC9v_AX3903ZJ5xw&s=09',
    linkedIn: '',
    img: '../images/toroh.jpeg',
  },
];

export default teamData;
