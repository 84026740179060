import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppProvider } from './context';
import {
  // createBrowserRouter,
  // RouterProvider,
  BrowserRouter,
} from 'react-router-dom';
import './index.css';
import App from './App';
// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//   },
// ]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);
