import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import db from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function Post() {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function fetchSingleData() {
      const docRef = doc(db, 'post', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
      }
    }
    fetchSingleData();
  }, []);
  return (
    <main class='p-6 bg-fwhite text-black flex flex-col items-center'>
      <section class='flex flex-col '>
        <img class='object-fill h-80 w-screen z-16' src={post?.image} alt='' />
        <h1 class='text-center z-10 px-4 text-4xl sm:text-5xl'>
          {post?.title}
        </h1>
      </section>
      <div class='max-w-4xl flex flex-col justify-center items-center'>
        <h2>{post?.author}</h2>
        Published: {post?.date}
        <div class='text-xl py-6  '>
          <h2 class='py-6'>{post?.heading}</h2>
          <p class='py-6'>{post?.content}</p>
          <p class='py-6'>{post?.paragraph2}</p>
          <h2 class='py-6'>{post?.subheading}</h2>
          <p class='py-6'>{post?.paragraph3}</p>
          <p class='py-6'>{post?.paragraph4}</p>
          <p class='py-6'>{post?.paragraph5}</p>
        </div>
      </div>
    </main>
  );
}

export default Post;
