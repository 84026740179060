import React, { useEffect, useState } from 'react';
import { MdReadMore } from 'react-icons/md';
import { useGlobalContext } from '../context';
import { Link } from 'react-router-dom';
function Blog() {
  const { data } = useGlobalContext();
  const [activeFilter, setActiveFilter] = useState('All');
  const [activeData, setActiveData] = useState([]);
  const handleWorkFilter = (item) => {
    setActiveFilter(item);

    if (item === 'All') {
      return setActiveData(data);
    }

    const filterData = data.filter((data) => data.category === item);
    setActiveData(filterData);
  };
  useEffect(() => {
    if (data) {
      setActiveData(data);
    }
  }, [data]);
  const allCategories = ['All', ...new Set(data.map((item) => item.category))];
  return (
    <section class='w-screen bg-fwhite py-16 px-8'>
      <div>
        <div>
          <div class='flex flex-col md:flex-row md:justify-between md:items-center '>
            <h1 class='text-2xl sm:text-3xl text-deepblue  font-bold md:text-5xl'>
              Blockchain UNILORIN Blog
            </h1>
            <p class='mt-6 md:w-1/2 lg:w-1/3'>
              Get the latest news and educative contents on blockchain and
              technology within Kwara state and Nigeria at large
            </p>
          </div>
          <div class='my-4'>
            {allCategories.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleWorkFilter(item)}
                  class={`p-1 border-blue border-2 rounded-md mx-2 capitalize ${
                    activeFilter == item && 'bg-lightblue'
                  }`}
                >
                  {item}
                </button>
              );
            })}
          </div>
          <hr class='bg-deepblue' />
        </div>
        {activeData &&
          activeData.map((item) => {
            const { author, content, title, date, id, image, category } = item;

            return (
              <div key={id}>
                <div>
                  <section class='mt-6 flex flex-col md:flex-row gap-4'>
                    <article class='flex flex-col md:w-1/2'>
                      <div class='relative'>
                        <img src={image} alt='' class='w-full h-64' />
                        <div class='flex flex-row justify-between bg-lightblue  h-24 items-center w-full absolute top-40 px-4 text-white'>
                          <div>
                            <h1>{author}</h1>
                            <p>{date}</p>
                          </div>
                          <p class='capitalize'>{category}</p>
                        </div>
                      </div>
                      <div class='p-4 text-deepblue'>
                        <h1 class='font-bold my-2'>{title}</h1>
                        <p>{content.substring(0, 150)}</p>
                        <Link
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                            });
                          }}
                          to={`/post/${id}`}
                        >
                          <button class='mt-3 flex flex-row items-center'>
                            Read post
                            <MdReadMore />
                          </button>
                        </Link>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
}

export default Blog;
