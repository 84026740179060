import React from 'react';
import { FaHandsHelping } from 'react-icons/fa';

function Partnership() {
  return (
    <section class='w-screen bg-fwhite flex flex-col justify-center items-center py-16 text-black px-8'>
      <h1 class='text-4xl sm:text-5xl text-deepblue  font-bold md:text-6xl'>
        SPONSORSHIPS
      </h1>
      <FaHandsHelping class='h-64 w-64 text-deepblue' />
      <p class=' text-lg text-center md:max-w-4xl '>
        Blockchain UNILORIN is a non-profit community which aim to educate as
        many students as possible out of above 50,000 students UNILORIN has to
        accelerate the growth and adoption of blockchain technology
      </p>
      <button class='bg-deepblue text-white p-2 rounded-md mt-3 hover:bg-blue'>
        <a href='mailto:blockchainunilorin@gmail.com'>Become a sponsor</a>
      </button>
    </section>
  );
}

export default Partnership;
