import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../context';
function Newsfeed() {
  const { data } = useGlobalContext();
  return (
    <section class='bg-fwhite w-screen px-6 py-8'>
      <h1 class=' text-3xl sm:text-4xl text-center text-deepblue font-bold md:text-5xl mb-4'>
        RECENT POSTS
      </h1>
      <div class='flex flex-col sm:flex-row sm:flex-wrap justify-center items-center text-center gap-x-3   gap-3'>
        {data?.map((item) => {
          const { author, content, title, date, id } = item;
          return (
            <div
              key={id}
              class='w-4/5  sm:w-2/4 md:w-2/6  h-[300px] bg-lightblue/30  flex flex-col gap-y-8 p-6'
            >
              <div class=''>
                <h2 class='text-2xl font-bold mb-3'>{title}</h2>
                <p>
                  {content.substring(0, 100)}
                  <Link
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                      });
                    }}
                    to={`/post/${id}`}
                  >
                    <span class='text-blue'> Read more </span>
                  </Link>
                </p>
              </div>
              <div class='text-black/50'>
                <p class='text-lg'>by {author}</p>
                <p>{date}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Newsfeed;
