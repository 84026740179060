import React from 'react';
import { motion } from 'framer-motion';
import {
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SiTelegram } from 'react-icons/si';
import styled from 'styled-components';
function Hero() {
  return (
    <section class='h-100 flex flex-col items-center w-screen text-black bg-lightblue border-y border-1'>
      <div class='z-10 p-4  text-center  flex flex-col items-center gap-y-3'>
        <h1 class='text-2xl sm:text-4xl md:text-5xl text-blue'>Welcome to</h1>
        <h1 class='font-bold text-deepblue text-4xl sm:text-5xl md:text-6xl'>
          BLOCKCHAIN UNILORIN
        </h1>
        <div class='flex justify-center gap-x-3 text-center text-2xl sm:text-3xl '>
          <a
            class='border border-gray-900 p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-blue hover:text-white  '
            target='_blank'
            href='https://twitter.com/bchainunilorin'
          >
            <AiOutlineTwitter />
          </a>
          <a
            class='border border-gray-900 p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-blue hover:text-white '
            target='_blank'
            href='https://www.linkedin.com/company/blockchainunilorin'
          >
            <AiOutlineLinkedin />
          </a>
          <a
            class='border border-gray-900 p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-blue hover:text-white '
            target='_blank'
            href='https://t.me/bchainunilorin'
          >
            <SiTelegram />
          </a>
          <a
            class='border border-gray-900 p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-blue hover:text-white '
            target='_blank'
            href='https://chat.whatsapp.com/KgUcaG2W0JY0L5Go0vYU8g
'
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
        <div class='w-3/5 sm:w-2/5 md:w-4/12'>
          <img class='h-40 w-full' src='../images/member.png' alt='' />
          <Link to='/registration'>
            <button class='bg-deepblue text-sm w-full mt-6 h-16 font-bold text-white rounded-md'>
              BECOME A MEMBER
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Hero;
