import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Children,
} from 'react';
import { collection, addDoc, query, getDocs } from 'firebase/firestore';
import db from './firebase';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const q = query(collection(db, 'post'));
        const querySnapshot = await getDocs(q);
        let data = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setData(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchdata();
  }, []);
  return <AppContext.Provider value={{ data }}>{children}</AppContext.Provider>;
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
