import React from 'react';
import {
  AiOutlineLinkedin,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import { SiTelegram } from 'react-icons/si';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer class='bg-fwhite pt-8 w-screen border-t-deepblue/50 border-t-2'>
      <div class=' flex justify-center'>
        <img class='h-16 w-16' src='../images/bchainlogo.PNG' alt='' />
      </div>
      <div class='text-md sm:text-2xl flex p-6  justify-center gap-x-3 sm:gap-x-6 text-black'>
        <Link
          to='/'
          onClick={() => {
            window.scrollTo({
              top: 0,
            });
          }}
        >
          <h1 class='hover:text-blue'>HOME</h1>
        </Link>
        <Link to='/course'>
          <h1 class='hover:text-blue '>COURSES</h1>
        </Link>
        <Link
          to='/blog'
          onClick={() => {
            window.scrollTo({
              top: 0,
            });
          }}
        >
          <h1 class='hover:text-blue '>BLOG</h1>
        </Link>
        <h1 class=' hover:text-blue'>
          <a
            target='_blank'
            href='https://drive.google.com/drive/folders/1JconqeK51lCgxljVaF9Zvwt5kmGOU5d6'
          >
            DOCUMENTS
          </a>
        </h1>
      </div>
      <div class='flex justify-around z-15 items-center w-full p-6 '>
        <div class='flex w-72 justify-around text-3xl  text-black '>
          <a
            class='border border-black p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-lightblue '
            href='https://twitter.com/bchainunilorin'
            target='_blank'
          >
            <AiOutlineTwitter />
          </a>
          <a
            class='border border-black p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-lightblue'
            href='https://www.linkedin.com/company/blockchainunilorin'
            target='_blank'
          >
            <AiOutlineLinkedin />
          </a>
          <a
            class='border border-black p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-lightblue'
            href='https://t.me/bchainunilorin'
            target='_blank'
          >
            <SiTelegram />
          </a>
          <a
            class='border border-black p-2 rounded-full cursor-pointer ease-in-out duration-300 hover:bg-lightblue'
            target='_blank'
            href='https://chat.whatsapp.com/KgUcaG2W0JY0L5Go0vYU8g
'
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
      <footer class='bg-deepblue w-screen h-10 text-sm text-center text-white py-3 '>
        BLOCKCHAIN UNILORIN 2023 &#169;. All rights reserved
      </footer>
    </footer>
  );
}

export default Footer;
