import Header from './components/Header';
import Main from './components/Main.js';
import { Route, Routes } from 'react-router-dom';
import Blog from './components/Blog';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import Course from './components/Course';
import Registration from './components/Registration';
import Post from './components/Post';
function App() {
  return (
    <div class='flex flex-col items-center overflow-hidden select-none'>
      <Header />
      <Routes>
        <Route path='' element={<Main />} />
        <Route path='blog' element={<Blog />} />
        <Route path='post/:id' element={<Post />} />
        <Route path='course' element={<Course />} />
        <Route path='registration' element={<Registration />} />
        <Route path='portfolio/:id' element={<Portfolio />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
