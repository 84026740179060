import React, { useEffect, useState } from 'react';
import { AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import teamData from './teamMembers';
import { useParams } from 'react-router-dom';

function Portfolio() {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  useEffect(() => {
    const { name, bio, title, linkedIn, twitter, img, details, portfolio } =
      teamData[id];
    const singleDetail = {
      name,
      bio,
      title,
      linkedIn,
      twitter,
      img,
      details,
      portfolio,
    };
    setDetail(singleDetail);
  }, [id]);
  return (
    <main class='w-screen '>
      <section class='flex p-6 bg-fwhite text-center flex-col-reverse gap-6 justify-between items-center '>
        <div class='md:max-w-4xl'>
          <h1 class='text-blue text-bold text-xl uppercase'>{detail?.name}</h1>
          <h2 class='text-blue mt-4 text-bold text-xl uppercase'>
            {detail?.title}
          </h2>
          <p class='text-black mt-4'>{detail?.details}</p>
          {detail?.portfolio && (
            <button class='text-blue bg-deepblue p-2 mt-3 rounded-md hover:text-black hover:bg-blue'>
              <a target='_blank' href={detail?.portfolio}>
                VISIT PORTFOLIO
              </a>
            </button>
          )}
          <p class='flex items-center justify-center text-xl gap-2 mt-6 text-deepblue'>
            <div class='border-2 border-blue'>
              <a target='_blank' href={detail?.twitter}>
                <AiFillTwitterSquare />
              </a>
            </div>
            <div class='border-2 border-blue'>
              <a target='_blank' href={detail?.linkedIn}>
                <AiFillLinkedin />
              </a>
            </div>
          </p>
        </div>
        <img class='w-64 h-64  rounded-full' src={`${detail?.img}`} alt='' />
      </section>
      <section class='p-6 '>
        <h1 class='text-blue'>ABOUT</h1>
        <p class='md:max-w-4xl text-black'>{detail?.bio}</p>
      </section>
    </main>
  );
}

export default Portfolio;
