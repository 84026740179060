import React from 'react';
import { Link } from 'react-router-dom';
import teamData from './teamMembers';
import { motion } from 'framer-motion';
function Team() {
  return (
    <section class='w-screen bg-fwhite flex flex-col justify-center items-center py-16 text-black px-8'>
      <h1 class='text-4xl sm:text-5xl text-deepblue  font-bold md:text-6xl mb-6'>
        TEAM
      </h1>
      <div class='flex flex-col h-full justify-evenly gap-6 sm:flex-row sm:flex-wrap '>
        {teamData.map((item) => {
          const { id, name, title, img } = item;
          return (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              key={id}
              class='sm:w-1/3 lg:w-1/4'
            >
              <div class='flex flex-col items-center justify-center text-center items-center gap-6 p-6'>
                <img class='w-40 h-40 rounded-full' src={img} alt='' />
                <div class=' text-xl mt-4'>
                  <h2 class=' font-bold uppercase w-72'>{name}</h2>
                  <h2>{title}</h2>
                  <button class='border-2 mt-2 p-2 rounded-md hover:text-blue'>
                    <Link
                      to={`/portfolio/${id}`}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                        });
                      }}
                    >
                      <h2>VIEW PORTFOLIO</h2>
                    </Link>
                  </button>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </section>
  );
}

export default Team;
