import React from 'react';
import { motion } from 'framer-motion';
import { FcConferenceCall } from 'react-icons/fc';
import { GiFlagObjective } from 'react-icons/gi';
function About() {
  return (
    <section class='w-screen bg-fwhite flex flex-col justify-center items-center py-16 px-8'>
      <h1 class='text-4xl sm:text-5xl text-deepblue  font-bold md:text-6xl'>
        ABOUT US
      </h1>
      <img src='../images/about.png' alt='' className='w-72 h-72' />
      <p class=' text-lg text-center md:max-w-4xl '>
        Blockchain UNILORIN is a campus blockchain community for the students of
        UNIVERSITY OF ILORIN. It is a community to learn about blockchain
        technology and connect with like minded individuals within the school
        vicinity
      </p>

      <h2 class='text-3xl sm:text-4xl text-deepblue  font-bold md:text-5xl mt-8'>
        OUR MISSION
      </h2>
      <GiFlagObjective class='h-64 w-64 text-deepblue' />
      <ul class=' text-lg'>
        <li>
          1. To <span class='text-2xl text-bold'> ADVOCATE </span> the adoption
          and implementation of blockchain technology in various industries
        </li>
        <li>
          2. To <span class='text-2xl text-bold'> EDUCATE </span> and
          <span class='text-bold text-2xl'> ENLIGHTEN </span> students about the
          technology, potential applications of blockchain and get them
          certified upon qualifications
        </li>
        <li>
          3. To promote <span class='text-2xl text-bold'> RESEARCH </span> and
          <span class='text-2xl text-bold'> DEVELOPMENT </span> in the field of
          blockchain technology and its applications.
        </li>
        <li>
          4. To provide a platform for students and industry professionals to
          <span class='text-2xl text-bold'> NETWORK </span>, exchange ideas and
          collaborate on blockchain-related projects.
        </li>
        <li></li>
      </ul>
    </section>
  );
}

export default About;
